<template lang="html">
    <div id="register">
        <div class="main">
            <h2>{{$config.websiteTitle}}注册</h2>
            <!-- <p class="tips"></p> -->
            <div class="box">
				<div class="">
					<input type="text" name="" v-model="formData.name" placeholder="请输入公司名称">
				</div>
				<div class="">
					<input type="text" name="" v-model="formData.realname" placeholder="请输入真实姓名">
				</div>
				<div class="">
					<input type="text" name="" v-model="formData.mobile" placeholder="请输入手机号" maxlength="11" >
					<button type="button" v-if="seconds==0" @click="handleGetCode">获取验证码</button>
					<button type="button" v-else>{{seconds}}s后重新获取</button>
				</div>
				<div class="">
					<input type="text" name="" v-model="formData.code" placeholder="请输入验证码" oninput="value=value.replace(/[^\d.]/g,'')">
				</div>
				<!-- <template>
                    <el-popover
                        placement="top-start"
                        title="入驻须知"
                        width="200"
                        trigger="hover"
                        content="
本标准适用于平台开放平台所有卖家。
1.平台开放平台暂未授权任何机构进行代理招商服务，入驻申请流程
及相关的收费说明均以平台开放平台页面为准。
2.平台开放平台将结合商户平台需求，不定期更新收费标准。
3.商家必须如实提供资料和信息。
4.买个商户的服务开通之日以平台通知或系统记录的时间为准。
5.卖家主动要求停止店铺服务的不返还平台使用费。
6.卖家因违规行为或资质造假被清退的不返还平台使用费。
7.平台使用费：卖家依照与平台签署的相关协议使用平台开放平台
商户提现服务费用。平台开放平台各经营类目对应的平台使用费标准
详见《微信支付商户结算规则、费率、周期说明》。">
                        <el-checkbox v-model="checked" slot="reference">平台入驻须知</el-checkbox>
                    </el-popover>
                </template> -->
                <button style="margin-top:20px" type="button" @click="register()">注册</button>
            </div>
        </div>
        <h4>技术支持&copy;河北织脉云软件开发有限公司</h4>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            checked: false,
            formData: {
                name: '',
                mobile: '',
                code: '',
				realname:''
            },
            seconds: 0,
        };
    },
	mounted(){
		if (this.$route.params.form == 'login') {
            this.$router.go(0)
        }
	},
    methods: {
        handleGetCode() {
			if(this.formData.mobile&&/^1[0-9][0-9]{9}$/.test(this.formData.mobile)){
				this.http.post('/Login/mobileCode',{
					mobile:this.formData.mobile
				}).then(re=>{
					this.$message({
						message: '验证码已发送到您的手机，请注意查收！',
						type: 'success'
					});
					this.seconds=60;
					var timer=setInterval(() => {
						this.seconds--;
						if (this.seconds == 0) {
							clearInterval(timer);
						}
					}, 1000)
				})
			}else{
				this.$message.error('请输入正确的手机号！');
			}
        },
        register: function() {
            if (!this.formData.name) {
                this.$message.error('请输入公司名称！');
            }else if (!this.formData.realname) {
                this.$message.error('请输入真实名称！');
            } else if (!(this.formData.mobile&&/^1[0-9][0-9]{9}$/.test(this.formData.mobile))) {
                this.$message.error('请输入正确手机号！');
            } else if (!this.formData.code) {
                this.$message.error('请输入验证码！');
            } else {
                this.http.post('/admin.company/register', this.formData).then(re => {
                    // this.$message({
                    //     message: '公司注册成功，正在跳往登录页面...',
                    //     type: 'success'
                    // });
					this.$message({
                        message: '公司注册成功，正在登录...',
                        type: 'success'
                    });
                    setTimeout(() => {
						this.$router.push({
							name:'可视化统计',
							params:{
								form:'login'
							}
						});
                    }, 1500)
                })
            }
        }
    }
}
</script>
